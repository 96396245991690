import axios from "axios";

/**
 * Utility function to set up application configurations.
 */
const initializeApp = () => {
    configureAxios();
    setupEnvironment();
};

/**
 * Configure Axios with base URL and default settings.
 */
const configureAxios = () => {
    const baseURL = process.env.REACT_APP_API_BASE_URL;

    if (!baseURL) {
        console.error("Base URL is not defined. Please set REACT_APP_API_BASE_URL in your environment.");
        throw new Error("Base URL is missing in environment variables.");
    }

    axios.defaults.baseURL = baseURL;
    console.info(`Axios base URL set to: ${baseURL}`);
};

/**
 * Setup environment-specific configurations.
 */
const setupEnvironment = () => {
    const env = process.env.NODE_ENV;

    if (!env || env === "development") {
        // Development-specific setup
        console.info("Application is running in development mode.");
    } else if (env === "production") {
        // Production-specific setup
        disableConsoleLogs();
        initializeAnalytics();
    } else {
        console.warn(`Unknown environment: ${env}`);
    }
};

/**
 * Disable console logs in production for security and performance.
 */
const disableConsoleLogs = () => {
    console.log = () => {};
    console.debug = () => {};
    console.info = () => {};
    console.warn = () => {};
    console.error = () => {}; // Optional, depending on needs
    console.info("Console logs are disabled in production mode.");
};

/**
 * Initialize analytics for production.
 */
const initializeAnalytics = () => {
    // Example placeholder for analytics setup (e.g., Google Analytics, Mixpanel, etc.)
    console.info("Analytics initialized."); // Replace with actual analytics initialization logic
};

export default initializeApp;
