import axios from "axios";

const checkAuth = () => {
    // Retrieve token from localStorage
    const TOKEN = localStorage.getItem("token");

    // Define public routes that don't require authentication
    const PUBLIC_ROUTES = ["login", "forgot-password", "register", "documentation"];
    const currentPath = window.location.pathname;

    // Check if the current page is public
    const isPublicPage = PUBLIC_ROUTES.some(route => currentPath.includes(route));

    // Redirect to login if token is missing and not on a public page
    if (!TOKEN && !isPublicPage) {
        window.location.href = "/login";
        return;
    }

    // Set authorization header for axios
    if (TOKEN) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${TOKEN}`;

        // Add global axios interceptors for loading indicators
        axios.interceptors.request.use(
            (config) => {
                document.body.classList.add("loading-indicator"); // Show loading indicator
                return config;
            },
            (error) => {
                document.body.classList.remove("loading-indicator");
                return Promise.reject(error);
            }
        );

        axios.interceptors.response.use(
            (response) => {
                document.body.classList.remove("loading-indicator"); // Hide loading indicator
                return response;
            },
            (error) => {
                document.body.classList.remove("loading-indicator");
                // Handle token expiration or unauthorized responses
                if (error.response?.status === 401 && !isPublicPage) {
                    localStorage.removeItem("token"); // Clear invalid token
                    window.location.href = "/login"; // Redirect to log in
                }
                return Promise.reject(error);
            }
        );

        return TOKEN;
    }
};

export default checkAuth;
